import React from "react";
import SEO from "../components/SEO/seo";
import Image from "../components/Image";
import Box from "../base/Box";
import Section from "../components/Section";
import ButtonLink from "../components/ButtonLink";

const NotFoundPage = () => (
  <>
    <SEO templateTitle="404: Not found" />
    <Section
      sx={{
        textAlign: "center",
      }}
    >
      <Image svgIcon="not-found-image" />
      <Box
        as="h3"
        sx={{
          fontWeight: "bold",
        }}
      >
        Page Not Found
      </Box>
      <p>We're sorry, the page you requested could not be found.</p>
      <p>Please go back to the homepage.</p>
      <ButtonLink
        isLink="/"
        sx={{
          fontWeight: "light",
          mt: 5,
        }}
        variant="buttons.secondary"
      >
        Go Home
      </ButtonLink>
    </Section>
  </>
);

export default NotFoundPage;
