import React from "react";
import PropTypes from "prop-types";
import LinkTo from "../LinkTo";

const ButtonLink = ({ children, isButton, sx, ...rest }) => (
  <LinkTo {...rest} isButton={isButton} sx={sx}>
    {children}
  </LinkTo>
);

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  isButton: PropTypes.bool,
  sx: PropTypes.shape({}),
};

ButtonLink.defaultProps = {
  isButton: true,
  sx: {},
};

export default ButtonLink;
