import PropTypes from "prop-types";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { camelCase } from "change-case";
import Box from "../../base/Box";
import Section from "../Section";
import MarkdownToHtml from "../MarkdownToHtml";
import ButtonLink from "../ButtonLink";

const PageHeroBanner = ({
  actionButton,
  bannerImage,
  content,
  children,
  sx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Section
      bgImage={bannerImage}
      overlay
      overlaySx={{
        background: [
          "rgba(255,255,255,.5)",
          null,
          null,
          "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6727065826330532) 25%, rgba(255,255,255,0) 60%);",
        ],
      }}
      sx={{
        bg: "primary",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100%",
        pb: [
          theme.sizes.heights.header[0],
          null,
          null,
          theme.sizes.heights.header[1],
        ],
        pt: [
          theme.sizes.heights.header[0],
          null,
          null,
          theme.sizes.heights.header[1],
        ],
        ...sx,
      }}
      {...rest}
    >
      {children || (
        <Box
          sx={{
            maxWidth: ["100%", null, null, "60%"],
            pb: [
              theme.sizes.heights.header[0],
              null,
              null,
              theme.sizes.heights.header[1],
            ],
            pt: [
              theme.sizes.heights.header[0],
              null,
              null,
              theme.sizes.heights.header[1],
            ],
          }}
        >
          <MarkdownToHtml
            content={content.text}
            contentSx={{
              p: { fontWeight: "medium" },
            }}
            heading={content.heading}
            headingSx={{
              h1: {
                color: "text.para",
              },
            }}
            sx={{
              color: "text.para",
            }}
          />
          {actionButton && actionButton.buttonLabel && (
            <ButtonLink
              key={`${camelCase(actionButton.buttonLabel)}`}
              isButton
              isLink={actionButton.buttonPath}
              sx={{
                "&:hover": { bg: "highlight", color: "white" },
                bg: "rgba(255, 255, 255, .4)",
                color: "text.para",
                fontSize: [0, null, null, 2],
                fontWeight: "bold",
                mt: 4,
                p: [2, null, null, 3],
                textAlign: ["center", null, null, "center"],
              }}
              variant="buttons.primary"
            >
              {actionButton.buttonLabel}
            </ButtonLink>
          )}
        </Box>
      )}
    </Section>
  );
};

PageHeroBanner.propTypes = {
  actionButton: PropTypes.shape({
    buttonLabel: PropTypes.string,
    buttonPath: PropTypes.string,
  }),
  bannerImage: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.shape({
    heading: PropTypes.string,
    text: PropTypes.string,
  }),
  sx: PropTypes.shape({}),
};

PageHeroBanner.defaultProps = {
  actionButton: null,
  bannerImage: null,
  children: null,
  content: null,
  sx: {},
};

export default PageHeroBanner;
